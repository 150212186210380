/**
 * Natural sort function that handles numeric and non-numeric parts of strings
 * Example: "010" comes before "0100", "Unit 1" before "Unit 2", etc.
 */
export const naturalSort = (a: string, b: string): number => {
    // Handle null/undefined/empty values
    if (!a && !b) return 0;
    if (!a) return -1;
    if (!b) return 1;

    // Split strings into chunks of numbers and non-numbers
    const splitIntoChunks = (str: string) => {
        return str.match(/([0-9]+|[^0-9]+)/g) || [];
    };

    const chunksA = splitIntoChunks(a);
    const chunksB = splitIntoChunks(b);

    // Compare each chunk
    const len = Math.min(chunksA.length, chunksB.length);
    for (let i = 0; i < len; i++) {
        const chunkA = chunksA[i];
        const chunkB = chunksB[i];

        // If both chunks are numeric, compare as numbers
        if (/^\d+$/.test(chunkA) && /^\d+$/.test(chunkB)) {
            const numA = parseInt(chunkA, 10);
            const numB = parseInt(chunkB, 10);
            if (numA !== numB) {
                return numA - numB;
            }
        } else {
            // Compare as strings, case-insensitive
            const compareResult = chunkA.localeCompare(chunkB, undefined, { 
                sensitivity: 'base',
                numeric: true 
            });
            if (compareResult !== 0) {
                return compareResult;
            }
        }
    }

    // If all chunks match up to the length of the shorter string,
    // shorter string comes first
    return chunksA.length - chunksB.length;
}; 