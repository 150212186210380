import React, { useRef } from "react";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { HttpStatusCode } from "axios";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { useWritsOfPossessionContext } from "../../WritsOfPossessionContext";
import { useAuth } from "context/AuthContext";
import InputMask from "react-input-mask";
import HelperViewPdfService from "services/helperViewPdfService";
import { IWritsLabor, IWritsOfPossessionItems, ISelectedWrit } from "interfaces/writs-of-possession.interface";
import { IGridHeader } from "interfaces/grid-interface";
import Grid from "components/common/grid/GridWithToolTip";
import { convertToEasternISOString } from "utils/helper";
import Button from "components/common/button/Button";
import Modal from "components/common/popup/PopUp";
import Spinner from "components/common/spinner/Spinner";
import DropdownPresentation from "components/common/dropdown/DropDown";
import GridCheckbox from "components/formik/GridCheckBox";
import { formattedDate, toCssClassName } from "utils/helper";
import dollarImage from "assets/images/dollar-sign.svg";
import WritsOfPossessionService from "services/writs-of-possesson.service";
import "pages/all-cases/components/AllCasesActions/AllCases_AddtoWrits";
import calendarImage from "assets/images/calendar-icon.svg";
import { IWritsCase, ITenant } from "interfaces/all-cases.interface";
import { FaTimes } from "react-icons/fa";
import { WritsReasonList, WritApplicantISList, WritLabourList} from "utils/constants";
import { ISelectOptions } from "interfaces/late-notices.interface";
import { Guid } from "guid-typescript";
import WritLaborService from "services/writ-labor.service";
import WritsOfPossessionDocumentUploader from "./WritsofPossessionDocumentUploader";



type AddtoWritLaborModalProps = {
  showAddtoWritLaborModalPopup: boolean;
  handleClose: () => void;
//   handleReviewSign: () => void;
//   errorMsg: string;
//    unFinedCases: string;
// handleFinish: () => void;
};

const validationSchema: yup.ObjectSchema<any> = yup.object({

});

const initialColumnMapping:IGridHeader[] = [
 
  { columnName: "isChecked", label: "Bulk Edit", controlType: "checkbox", toolTipInfo: "This checkbox represents bulk update only" },
  { columnName: "county", label: "County" },
  { columnName: "caseNo", label: "CaseNo", toolTipInfo: "The unique number or code associated with your case, as assigned by the court. " },
  { columnName: "propertyName", label: "PropertyName Vs. Tenants" },
  { columnName: "address", label: "TenantAddressCombined" },
  { columnName: "reason", label: "WritReason", toolTipInfo: "The reason for which you are filing a writ of possession. " },
  { columnName: "writOrderDate", label: "WritOrderDate", toolTipInfo: "The date the writ order was issued by the court. " },
  { columnName: "paymentAmountOwned", label: "WritPaymentOwed" },
  { columnName: "paymentDueOn", label: "WritPaymentDueOn" },
  { columnName: "writComment", label: "WritComments", toolTipInfo: "You must clearly state how the tenant failed to meet the terms of the Consent Judgment/Order/Agreement." },
  { columnName: "writApplicantIS", label: "WritApplicantIs", toolTipInfo: "The relationship that the writ affiant has to the case. This is usually Owner, Attorney, or Agent. " },
  { columnName: "writApplicantPhone", label: "WritApplicantPhone" },
  { columnName: "writLaborId", label: "WritLabor" },
  { columnName: "hasSSN", label: "HaveSSN", toolTipInfo: "Select 'Yes' if you used the tenant's social security number to acquire SCRA documentation." },
  { columnName: "isCorporation", label: "IsCorporation", toolTipInfo: "Select 'Yes' if the tenant is a corporation." },
  { columnName: "militaryStatusReport", label: "MiliaryStatusPDF" }
  //  { columnName: "hasSSN", label: "HaveSSN", toolTipInfo: "Select 'Yes' if you used the tenant's social security number to acquire SCRA documentation." },
  //  { columnName: "isCorporation", label: "IsCorporation", toolTipInfo: "Select 'Yes' if the tenant is a corporation." },
  //  { columnName: "militaryStatusReport", label: "MiliaryStatusPDF" }
];

const WritsOfPossession_AddtoWritLaborModal = (props: AddtoWritLaborModalProps)=> {
  const { userRole } = useAuth();
  const buttonClasses: string = "text-[11px] md:text-xs bg-[#2472db] inline-flex justify-center items-center rounded-md font-semibold py-2 md:py-2.5 px-4 md:px-5 text-white"
  const {
    signedWrits,
    setShowSpinner,
    setAllSignedWrits,
    writLabors,
    getWritLabors,
    //setAllUnsignedWrits,
    getAllWritsOfPossession,
    showSpinner,
    setFilteredRecords,
    filteredRecords,
    setSelectedSignedWritsId,
    selectedWritsOfPossessionId,
    selectedSignedWritsId,
    setSelectedWritsOfPossessionId,
    setSelectedFilteredWritOfPossessionId,
    bulkRecords,
    setBulkRecords,
  } = useWritsOfPossessionContext();
  const isMounted = useRef(true);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [showSignInPopup, setShowSignInPopup] = useState<boolean>(false);
  const [showUploadReports, setShowUploadReports] = useState<boolean>(false);
  const [uploadReportsForCase, setUploadReportsForCase] = useState<ISelectedWrit | null>(null);
  const [disableReviewSign, setDisableReviewSign] = useState<boolean>(true);
  const [checkUnFinedCases, setCheckUnFindCases] = useState<boolean>(false);
  const [writLabourList, setWritLabourList] = useState<ISelectOptions[]>([]);
  const [validationMessage, setValidationMessage] = useState<string>('');
   const [showNonSupportedCountyMessage, setShowNonSupportedCountyMessage] = useState<string>("");
   const [showCountyMessage, setShowCountyMessage] = useState<string>("");
  const [showErrors, setShowErrors] = useState(false);
  const [otherApplicantISMap, setOtherApplicantISMap] = useState<Record<string, string>>({});
  const [visibleColumns, setVisibleColumns] = useState<IGridHeader[]>(
    initialColumnMapping
  );
  const confirmationMessage = showConfirm

  const isScraRequiredForCounty = (county: string) => {
    return county === "FULTON";
 };
 
 const isWritDetailsRequiredForCounty = (county: string) => {
    return county.toLowerCase() === "gwinnett";
 };
 
  useEffect(() => {
        //Setting default value for reason column
        const allCasesRecords = bulkRecords.map((item: any) => {
           const matchingRecord = filteredRecords.find((r) => r.id === item.id);
           return {
              ...item, // Spread existing properties
              reason: matchingRecord?.reason ?? "",
              writOrderDate: matchingRecord?.writOrderDate ?? "",
              paymentAmountOwned: matchingRecord?.paymentAmountOwned ?? "",
              paymentDueOn: matchingRecord?.paymentDueOn ?? "",
              writComment: matchingRecord?.writComment ?? "",
              writLaborId: matchingRecord?.writLaborId ?? "",
              hasSSN: matchingRecord?.hasSSN ?? "",
              writApplicantIS: matchingRecord?.writApplicantIS ?? "",
              otherApplicantIS: matchingRecord?.otherApplicantIS ?? "",
              writApplicantPhone: matchingRecord?.writApplicantPhone ?? "",
              isCorporation: matchingRecord?.isCorporation ?? "No",
              militaryStatusReport: matchingRecord?.militaryStatusReport ?? "",
           };
        });
  });
  const [selectAll, setSelectAll] = useState<boolean>(false);
  // const [selectedRows, setSelectedRows] = useState<Array<boolean>>(
  //   Array(signedWrits.items?.length).fill(false)
  // );
  const [columnErrors, setColumnErrors] = useState<
    Record<string, { rowIndex: number; errorMessage: string }[]>[]
  >([]);
  // const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selectFilteredAll, setSelectFilteredAll] = useState<boolean>(false);

  const [selectedFilteredRows, setSelectedFilteredRows] = useState<Array<boolean>>(
    Array(filteredRecords?.length).fill(false)
  );

  const [canPaginateBack, setCanPaginateBack] = useState<boolean>(
    signedWrits.currentPage > 1
  );
  const [canPaginateFront, setCanPaginateFront] = useState<boolean>(
    signedWrits.totalPages > 1
  );
  const [lastClickedFilteredRowIndex, setLastClickedFilteredRowIndex] = useState<number>(-1);
  const [shiftKeyPressed, setShiftKeyPressed] = useState<boolean>(false);

  /* useEffect(() => {  
    
  //   const records = bulkRecords.filter((item) =>
  //   selectedWritsOfPossessionId.includes(item.id || "")
  // );
  let uniqueRecords: { [key: string]: any } = {};
      let records = bulkRecords.filter((item) => {
         const id = item.id || "";
         if (!selectedWritsOfPossessionId.includes(id) || uniqueRecords[id]) {
            return false;
         }
         uniqueRecords[id] = true;
         return true;
      });
      setFilteredRecords(records);
    setFilteredRecords(records);
    setSelectedFilteredRows(Array(filteredRecords?.length).fill(false));
  }, []); */

     useEffect(() => {
        if (isMounted.current) {
           getWritLabors();
           isMounted.current = false;
        };
  
     }, []);
  useEffect(() => {
        //   const filterWritLabors =  writLabors.filter(x=>x.email != null && x.email != "" && x.phone != null && x.phone != "")
        const writLaborsList: ISelectOptions[] = writLabors.map((item: IWritsLabor) => {
           return {
              id: item.id,
              value: item.firstName
           } as ISelectOptions;
        });
  
        setWritLabourList(writLaborsList);
  
     }, [writLabors]);

  useEffect(() => {  
    if (!selectedWritsOfPossessionId || selectedWritsOfPossessionId.length === 0) {
      return;
    }
    console.log("bulk records", bulkRecords);
    const records = bulkRecords.filter((item) =>
      selectedWritsOfPossessionId.includes(item.id || "")
   );
   setFilteredRecords(records);
     
    console.log("Opening Edit Writ Modal with IDs:", selectedWritsOfPossessionId);
  
    const uniqueRecords: { [key: string]: any } = {};
    console.log("filter records", records)
    // let records = filteredRecords.filter((item) => {
    //   return true;
    //   const id = item.id || "";
    //   if (!selectedWritsOfPossessionId.includes(id) || uniqueRecords[id]) {
    //     console.log(`item id ${item.id}: FALSE`)
    //     return false;
    //   }
    //   console.log(`item id ${item.id}: TRUE`)
    //   uniqueRecords[id] = true;
    //   return true;
    // });
  
    setSelectedFilteredRows(Array(records.length).fill(false));
  }, [selectedWritsOfPossessionId, bulkRecords]); 

  useEffect(() => {
    setSelectFilteredAll(false);
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Shift") {
        setShiftKeyPressed(true);
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === "Shift") {
        // Reset selected rows to the top (index 0)
        setShiftKeyPressed(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  
  // set the state of selected dropdown
  const handleDropdownChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    rowId: string | undefined | null,
    rowIndex: number,
    dropdownType: string
  ) => {
    try {
      setShowSpinner(true);
      const selectedOption = event.target.value as string;

       // Map dropdownType to the corresponding column name
        const columnNameMap: Record<string, string> = {
        writApplicantIS: "writApplicantIS",
        writLaborId: "writLaborId",
        reason: "reason",
        };

        const columnName = columnNameMap[dropdownType];
      
      // Update only the specific row where the dropdown is changed
      const updatedGridData = filteredRecords.map((record, index) => {
        if (record.id === rowId) {
          return {
            ...record,
            [columnName]: selectedOption,
          };
        }
        return record;
      });

      setFilteredRecords(updatedGridData);

      //if (columnName === "selectedReason") {
      //  resetValuesOnReasonChange(rowIndex, selectedOption, columnName);
    // };

     // Handle "Other" option for writApplicantIS
     if (dropdownType === "writApplicantIS" && selectedOption === "Other") {
        // Set the "Other" value in writApplicantIS for this specific row
        setFilteredRecords((prevRecords) =>
           prevRecords.map((record, index) => {
              if (index === rowIndex) {
                 return {
                    ...record,
                    otherApplicantIS: otherApplicantISMap[rowId || ""] || "", // Set the "Other" value
                 };
              }
              return record;
           })
        );
     }

     // Perform validation for the updated row
     validateRow(updatedGridData[rowIndex], rowIndex);
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setShowSpinner(false);
    }
  };

  const validateField = async () => {
    const errors: Record<
      string,
      { rowIndex: number; errorMessage: string }[]
    >[] = [];
    filteredRecords.forEach((record, rowIndex) => {
      const recordErrors: Record<
        string,
        { rowIndex: number; errorMessage: string }[]
      > = {};

      try {
        validationSchema.validateSync(record, { abortEarly: false });
      } catch (error: any) {
        if (error.inner) {
          error.inner.forEach((detailError: any) => {
            const propertyName = detailError.path || "unknown";
            const errorMessage = `${detailError.message}`; // Corrected syntax
            const rowIndex = detailError?.rowIndex ?? -1;

            if (!recordErrors[propertyName]) {
              recordErrors[propertyName] = [];
            }

            recordErrors[propertyName].push({
              rowIndex,
              errorMessage,
            });
          });
        }
      }

      if (Object.keys(recordErrors).length > 0) {
        errors.push(recordErrors);
      }
    });
    setColumnErrors(errors);
  }

  // Update handleInputChange to use the validateField function
  const handleInputChange = async (
    columnName: string,
    updatedValue: string|boolean,
    selectedRowIndex: number
  ) => {
    // setDisableReviewSign(false);
    // If any row is selected, perform bulk update
    if (selectedFilteredRows[selectedRowIndex]) {
      setFilteredRecords((prevRows) =>
        prevRows.map((row, rowIndex) => {
          if (selectedFilteredRows[rowIndex] === selectedFilteredRows[selectedRowIndex]) {
            if (columnName === "reason") {
              if (updatedValue === "1") {
                 const updatedRow = {
                    ...row,
                    isCorporation: false,
                    [columnName]: updatedValue,
                 };
                 validateRow(updatedRow, rowIndex);
                 return updatedRow;
              }
              if (updatedValue === "2") {
                 const updatedRow = {
                    ...row,
                    paymentAmountOwned: "",
                    paymentDueOn: "",
                    isCorporation: false,
                    [columnName]: updatedValue,
                 };
                 validateRow(updatedRow, rowIndex);
                 return updatedRow;
              }
              else {
                 const updatedRow = {
                    ...row,
                    writOrderDate: "",
                    paymentAmountOwned: "",
                    paymentDueOn: "",
                    writComment: "",
                    isCorporation: false,
                    [columnName]: updatedValue as string,
                 };
                 validateRow(updatedRow, rowIndex);
                 return updatedRow;
              }
           };
            // If the row is selected, update the specified column
            const updatedRow = { ...row, [columnName]: updatedValue };
            // Perform validation for the updated row
            validateRow(updatedRow, rowIndex);
            return updatedRow;
          }

          else {
            // If the row is not selected, return the original row
            return row;
          }

        })
      );
    } else {

      // If no row is selected, update only the selected row
      setFilteredRecords((prevRows) =>
        prevRows.map((row, rowIndex) => {
          const updatedRow =
            rowIndex === selectedRowIndex
              ? { ...row, [columnName]: updatedValue }
              : row;
          // Perform validation for the updated row
          validateRow(updatedRow, rowIndex);
          return updatedRow;
        })
      );
    }
    
    
  };
  const fileWritsAsNonSigner = async () => {
    try {
       setShowConfirm(true);
       const fileWritsRequest = filteredRecords.map((item: IWritsOfPossessionItems) => ({
          caseId: item.id,
          reason: parseInt(item.reason ?? '0', 10),
          // item.EvictionDateFiled = item.EvictionDateFiled ? moment(item.EvictionDateFiled, 'MM/DD/YYYY').toISOString() : null;
          writOrderDate: item.writOrderDate ? convertToEasternISOString(item.writOrderDate as string) : null,
          // writOrderDate: item.writOrderDate ? new Date(item.writOrderDate) : new Date(),
          paymentAmountOwned: parseFloat(item.paymentAmountOwned ?? '0'),
          // paymentDueOn: item.paymentDueOn ? new Date(item.paymentDueOn) : new Date(),
          paymentDueOn: item.paymentDueOn ? convertToEasternISOString(item.paymentDueOn as string) : null,
          writComment: item.writComment ?? "",
          hasSSN: isScraRequiredForCounty(item.county) ? (item.hasSSN) : null,
          isCorporation: isScraRequiredForCounty(item.county) ? (item.isCorporation) : null,
          writApplicantIs: item.writApplicantIS === "Other" ? item.otherApplicantIS : item.writApplicantIS,
          writApplicantPhone: item.writApplicantPhone,
          writLaborId: item.writLaborId,
       } as IWritsCase));
    } catch (error) {
       toast.success("Something went wrong");
    } finally {
       setShowSpinner(false);
    }
 };
    // Update handleInputChange to use the validateField function
    const handleFieldCheckBoxChange = async (
      columnName: string,
      updatedValue: string|boolean,
      selectedRowIndex: number
    ) => {
      
      // setDisableReviewSign(false);
      // If any row is selected, perform bulk update
      if (selectedFilteredRows[selectedRowIndex]) {
        setFilteredRecords((prevRows) =>
          prevRows.map((row, rowIndex) => {
            if (selectedFilteredRows[rowIndex] === selectedFilteredRows[selectedRowIndex]) {
              // If the row is selected, update the specified column
              const updatedRow = { ...row, [columnName]: updatedValue };
              // Perform validation for the updated row
              validateRow(updatedRow, rowIndex);
              return updatedRow;
            }
  
            else {
              // If the row is not selected, return the original row
              return row;
            }
  
          })
        );
      } else {
  
        // If no row is selected, update only the selected row
        setFilteredRecords((prevRows) =>
          prevRows.map((row, rowIndex) => {
            const updatedRow =
              rowIndex === selectedRowIndex
                ? { ...row, [columnName]: updatedValue }
                : row;
            // Perform validation for the updated row
            validateRow(updatedRow, rowIndex);
            return updatedRow;
          })
        );
      }
    };
    const isSelectedFirstReason = (fieldName: string, rowIndex: number) => {
      const rowSelectedReason = filteredRecords[rowIndex]?.reason;
      return rowSelectedReason === "0" && ["writOrderDate", "paymentAmountOwned", "paymentDueOn", "writComment"].includes(fieldName);
   };
   const isSelectedThirdReason = (fieldName: string, rowIndex: number) => {
    const rowSelectedReason = filteredRecords[rowIndex]?.selectedReason;
    return rowSelectedReason === "2" && ["paymentAmountOwned", "paymentDueOn"].includes(fieldName);
 };
  const validateRow = (row: IWritsOfPossessionItems, rowIndex: number) => {
    const recordErrors: Record<
      string,
      { rowIndex: number; errorMessage: string }[]
    > = {};

    try {
      // Validate the updated row against the schema
      validationSchema.validateSync(row, { abortEarly: false });
    } catch (error: any) {
      if (error.inner) {
        // Collect validation errors for each property
        error.inner.forEach((detailError: any) => {
          const propertyName = detailError.path || "unknown";
          const errorMessage = `${detailError.message}`;

          // Get the row index from your record, adjust this based on your data structure
          const rowIndex = detailError.rowIndex || -1;

          // Check if the property already has errors, if not, initialize an array
          if (!recordErrors[propertyName]) {
            recordErrors[propertyName] = [];
          }

          // Push the error object with rowIndex to the array
          recordErrors[propertyName].push({
            rowIndex,
            errorMessage,
          });
        });
      }
    }

    // If there are errors for the record, update the columnErrors state
    setColumnErrors((prevErrors) => [
      ...prevErrors.slice(0, rowIndex),
      recordErrors,
      ...prevErrors.slice(rowIndex + 1),
    ]);
  };

  const convertToNumber = (value: string): string => {
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) ? "" : parsedValue.toString();
  };

  const [newSelectedRows] = useState<boolean[]>([]);

  const handleCheckBoxChange = (index: number, checked: boolean) => {
    
    if (shiftKeyPressed && lastClickedFilteredRowIndex !== -1 && filteredRecords) {
      const start = Math.min(index, lastClickedFilteredRowIndex);
      const end = Math.max(index, lastClickedFilteredRowIndex);
      setSelectedFilteredRows(Array.from({ length: end + 1 }, (_, i) =>
        i >= start && i <= end ? selectedFilteredRows[i] = true : newSelectedRows[i]
      ));
      setSelectedFilteredRows(selectedFilteredRows);
      const selectedIds = (filteredRecords || [])
        .filter((_, rowIndex) => selectedFilteredRows[rowIndex])
        .map((item) => item.id)
        .filter((id): id is string => typeof id === "string");
        setSelectedFilteredWritOfPossessionId(selectedIds);
    }
    else {
      const updatedSelectedRows = [...selectedFilteredRows];
      updatedSelectedRows[index] = checked;
      setSelectedFilteredRows(updatedSelectedRows);

      const selectedIds = (filteredRecords || [])
        .filter((_, rowIndex) => updatedSelectedRows[rowIndex])
        .map((item) => item.id)
        .filter((id): id is string => typeof id === "string");

        setSelectedFilteredWritOfPossessionId(selectedIds);
    }
    setLastClickedFilteredRowIndex(index);
  };
  const handleSelectAllChange = (checked: boolean) => {    
    const newSelectAll = !selectAll;
    const allIds: string[] = filteredRecords
      .map((item) => item.id)
      .filter((id): id is string => typeof id === "string");
    if (checked) {
      setSelectedFilteredWritOfPossessionId(allIds);
    } else {
      setSelectedFilteredWritOfPossessionId([]);
    }

    setSelectAll((prevSelectAll) => {
      // Update selectedRows state
      setSelectedFilteredRows(Array(allIds.length).fill(newSelectAll));
      return newSelectAll;
    });
  };

  const resetValuesOnReasonChange = (rowIndex: number, selectedOption: string, columnName: string) => {
    // Ensure filteredRecords is always an array
    if (!filteredRecords || !Array.isArray(filteredRecords)) {
        console.error("filteredRecords is undefined or not an array:", filteredRecords);
        setFilteredRecords([]); // Default to empty array to prevent errors
        return;
    }

  const updatedGridData = filteredRecords.map((record, index) => {
        if (index === rowIndex) {
            if (selectedOption === "1") {
                return {
                    ...record,
                    isCorporation: "No",
                    [columnName]: selectedOption,
                };
            }
            if (selectedOption === "2") {
                return {
                    ...record,
                    paymentAmountOwned: "",
                    paymentDueOn: "",
                    isCorporation: "No",
                    [columnName]: selectedOption,
                };
            }
            return {
                ...record,
                writOrderDate: "",
                paymentAmountOwned: "",
                paymentDueOn: "",
                writComment: "",
                isCorporation: "No",
                [columnName]: selectedOption,
            };
        }
        return record;
  });
};

  const [setOutCompletedDateSelected, setSetOutCompletedDateSelected] = useState<boolean>(false);

  // Handler to toggle SetOutCompleted checkbox based on SetOutCompletedDate selection
  const handleSetOutCompletedDateChange = (date: Date | null, rowIndex: number) => {
    
    // Check if date is selected
    if (date !== null) {
      // Date is selected, check SetOutCompleted and disable it
      handleInputChange("setOutCompleted", true, rowIndex);
      setSetOutCompletedDateSelected(true);
    } else {
      // Date is not selected, uncheck SetOutCompleted and enable it
      handleInputChange("setOutCompleted", false, rowIndex);
      setSetOutCompletedDateSelected(false);
    }
  };
  

  /**
   * Render each cell of a table
   * @param cellIndex  : cell of table
   * @param data  :data of cell
   * @param rowIndex : row index
   * @returns render cell
   */

  const handleCellRendered = (
    cellIndex: number,
    data: IWritsOfPossessionItems,
    rowIndex: number
  ) => {  
    const columnName = visibleColumns[cellIndex]?.label;
    const propertyName =visibleColumns[cellIndex]?.columnName;
    const cellValue = (data as any)[propertyName];
    const renderers: Record<string, () => JSX.Element> = {
    caseNo:()=><span>{cellValue !== null ? cellValue : ""}</span>,
    propertyName:()=><span>{cellValue !== null ? cellValue : ""}</span>,
    county:()=><span>{cellValue !== null ? cellValue : ""}</span>,
    name: () => formattedFullNameCell(data.firstName, data.lastName),
    address: () => formattedAddressCell(data),
    
    reason: () => (
      <div className="fields_validation">
         <DropdownPresentation
            heading={""}
            selectedOption={
               {
                  id: cellValue,
                  value: cellValue,
               } as ISelectOptions
            }
            handleSelect={(event) => { handleDropdownChange(event, data.id, rowIndex, "reason"); handleInputChange?.(propertyName, event.target.value, rowIndex) }}
            options={WritsReasonList}
            placeholder="Select an option"
            sort={false}
            disabled={data.county.trim().toLowerCase() === "gwinnett" ? true : false}
         />
         {showErrors && columnErrors[rowIndex]?.reason?.map((error, index) => (
            <div key={index} className="text-red-500 ml-2 mb-1">
               {error.errorMessage}
            </div>
         ))}
      </div>
   ),
   writApplicantIS: () => {
    const isOtherSelected = filteredRecords[rowIndex]?.writApplicantIS === "Other";
    return (
       <div className="fields_validation">
          <div className="flex">
             <DropdownPresentation
                heading={""}
                selectedOption={
                   {
                      id: cellValue,
                      value: cellValue,
                   } as ISelectOptions
                }
                handleSelect={(event) => { handleDropdownChange(event, data.id, rowIndex, "writApplicantIS"); handleInputChange?.(propertyName, event.target.value, rowIndex) }}
                options={WritApplicantISList}
                disabled={data.county.trim().toLowerCase() === "gwinnett" ? true : false}
                placeholder="Select an option"
             />
             {isOtherSelected && (
                <div className="ml-1 min-w-[100px] writApplicantOther">
                   <input
                      type="text"
                      value={filteredRecords[rowIndex]?.otherApplicantIS || ""}
                      onChange={(e) => handleInputChange("otherApplicantIS", e.target.value, rowIndex)}
                      placeholder="Enter Other Applicant IS"
                      className="peer outline-none p-2.5 py-1.5 block border w-full rounded-md text-xs placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none    "
                   />
                </div>
             )}
          </div>
          {showErrors && (
             <>
                {columnErrors[rowIndex]?.writApplicantIS?.map((error, index) => (
                   <div key={index} className="text-red-500 mt-1">
                      {error.errorMessage}
                   </div>
                ))}
                {filteredRecords[rowIndex]?.writApplicantIS === "Other" &&
                   columnErrors[rowIndex]?.otherApplicantIS?.map((error, index) => (
                      <div key={index} className="text-red-500 mt-1">
                         {error.errorMessage}
                      </div>
                   ))}
             </>
          )}
       </div>
    );
 },
 writLaborId: () => (
  <div className="fields_validation">
     <DropdownPresentation
        heading={""}
        selectedOption={
           {
              id: cellValue,
              value: cellValue,
           } as ISelectOptions
        }
        handleSelect={(event) => { handleDropdownChange(event, data.id, rowIndex, "writLaborId"); handleInputChange?.(propertyName, event.target.value, rowIndex) }}
        options={writLabourList}
        placeholder="Select an option"
     />
     {showErrors && columnErrors[rowIndex]?.writLaborId?.map((error, index) => (
        <div key={index} className="text-red-500 ml-2 mb-1">
           {error.errorMessage}
        </div>
     ))}
  </div>
),
    setOutCompletedDate:()=><div className="datePicker"><DatePicker
    selected={
      cellValue && Date.parse(cellValue as string)
        ? new Date(cellValue as string)
        :null// new Date()
    }
    onChange={(date: any) =>
      {handleInputChange?.(propertyName, date, rowIndex);
      handleSetOutCompletedDateChange(date, rowIndex)}
    }
    minDate={new Date("2000-12-12")}
    maxDate={new Date()}
    //dateFormat="MM/dd/yyyy"
    dateFormat="MM/dd/yyyy"
    placeholderText="mm/dd/yyyy"
    className="bg-calendar bg-no-repeat bg-[center_right_1rem] peer placeholder-gray-500 outline-none p-3 block border w-full border-gray-200 rounded-lg text-sm  focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none    "
  /></div>,
  setOutScheduled:()=><div className="ml-5 flex items-center">
          <input
            type="checkbox"
            className="form-checkbox"
            name={propertyName}
            checked={cellValue} // Set checked attribute based on state
            onChange={(event) => handleInputChange(propertyName,event.target.checked,rowIndex)}  // Handle change event
          />
          <span className="text-gray-600 text-sm font-medium ml-2">Yes</span>
        </div>,
  setOutCompleted:()=><div className="ml-5 flex items-center">
          <input
            type="checkbox"
            className="form-checkbox "
            name={propertyName}
            disabled={!cellValue}
            checked={cellValue} // Set checked attribute based on state            
          //  onChange={(event) => handleInputChange(propertyName,event.target.checked,rowIndex)} // Handle change event
          />
          <span className="text-gray-600 text-sm font-medium ml-2">Yes</span>
        </div>,
  writCancelled:()=><div className="ml-5 flex items-center">
          <input
            type="checkbox"
            className="form-checkbox"
            name={propertyName}
            checked={cellValue} // Set checked attribute based on state
            onChange={(event) => handleInputChange(propertyName,event.target.checked,rowIndex)} // Handle change event
          />
          <span className="text-gray-600 text-sm font-medium ml-2">Yes</span>
        </div>,
      isChecked: () => (
       <div className="selectRowCheckbox">
         <GridCheckbox
          checked={selectedFilteredRows[rowIndex]}
          onChange={(checked: boolean) =>
            handleCheckBoxChange(rowIndex, checked)
          }
          label=""
        />
       </div>
      ),
    
    };
  

    const renderer = renderers[propertyName] || (() => formattedCell(cellValue, columnName, propertyName, rowIndex, data.id ?? "", data));

    if (visibleColumns.find(x=>x.label===columnName)){
        
      return (
        <td
          key={cellIndex}
          className={`px-1.5 py-2 md:py-2.5 font-normal text-[10.3px] md:text-[11px] text-[#2a2929]  ${toCssClassName(columnName)}`}
        >
          {renderer()}
        </td>
      );
    }
    return <></>;
  };
  
  const formattedDateCell = (value: any) => (
    <span>{value != null && value !== "" ? formattedDate(value) : ""}</span>
  );

  const formattedFullNameCell = (firstName: string, lastName: string) => (
    <span>{`${firstName} ${lastName}`}</span>
  );

  const formattedAddressCell = (value: any) => (
    <span>
      {value !== null ? `${value.tenantAddress ?? ''} ${value.tenantUnit ?? ''} ${value.tenantCity ?? ''} ${value.tenantState ?? ''} ${value.tenantZip ?? ''}` : ''}
    </span>
  );

  const handleSubmit = async () => {     
  try {
    setShowSpinner(true);

    if (!filteredRecords || filteredRecords.length === 0) {
      toast.error("No records selected for editing.");
      return;
    }

    console.log("Saving Updated Writs Data:", filteredRecords);

    const response = await WritsOfPossessionService.editWritOfPossessionByWritLabor(filteredRecords);

    if (response.status = HttpStatusCode.Ok) {
      toast.success("Writs of possession successfully updated.");
      setBulkRecords(filteredRecords);
      props.handleClose();
    } else {
      toast.error("Failed to update writs of possession.");
    }
  } catch (error) {
    console.error("Error updating writs:", error);
    toast.error("An error occurred while updating.");
  } finally {
    setShowSpinner(false);
  }
};

  /*
  const handleModalClose = () => {
   // setFilteredRecords([]);
    //setSelectedWritsOfPossessionId([]);
    props.handleClose();   
  };*/
  const handleModalClose = () => {
    console.log("Closing Edit Writs Modal");
  
    setFilteredRecords([]);
    setSelectedWritsOfPossessionId([]);
    setSelectedFilteredRows([]);
    
    props.handleClose();
  };
  const renderNotApplicableField = (disabled: boolean) => {
    return (
       <div className="fields_validation">
          <input
             type={"text"}
             value={"N/A"}
             readOnly={true}
             disabled={true}
             className={"peer outline-none p-2.5 py-1.5 block border w-full rounded-md text-xs placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none    "}
          />
       </div>

    )
 };

 const handleMilitaryStatusReport = (rowIndex: number) => {
   // Get the selected record based on the rowIndex
   const selectedRecord = filteredRecords[rowIndex];

   // Set the state variable to show the modal and pass only the selected record
   setShowUploadReports(true);
   setUploadReportsForCase(selectedRecord);
};
const openPdf = async (url: string) => {
  HelperViewPdfService.GetPdfView(url);
};
const handleRemoveMSReport = async (tenant: ITenant) => {
  try {
     if (
        tenant.attachmentId !== Guid.EMPTY &&
        tenant.attachmentId !== null &&
        tenant.attachmentId !== '' &&
        tenant.attachmentId !== undefined
     ) {
        const response = await WritLaborService.removeMilitaryStatusReport(tenant.attachmentId);
        if (response.status === HttpStatusCode.Ok) {
           tenant.isMilitaryStatusUploaded = false;
           tenant.militaryStatusDocURL = "";
           tenant.attachmentId = Guid.EMPTY

           setFilteredRecords((prev) =>
              prev.map((item) => {
                 // Check if the tenant is part of tenantNames
                 if (item.tenantNames.some((t) => t.id === tenant.id)) {
                    return {
                       ...item,
                       militaryStatusReport: "", // Update militaryStatusReport at item level
                       tenantNames: item.tenantNames.map((t) =>
                          t.id === tenant.id ? { ...t, ...tenant } : t
                       )
                    };
                 }
                 return item;
              })
           );

           toast.success("Military status report removed successfully");
        }
     } else {
        toast.error("Military status report not found");
     }

  } catch (error) {
     console.log(error);
  }
};
 const isScraRequiredForIndex = (rowIndex: number) => {
  const county = filteredRecords[rowIndex]?.county;
  return isScraRequiredForCounty(county);
};

  const formattedCell = (value: any, columnName: any, fieldName: any, rowIndex: number, id: string, data: IWritsOfPossessionItems) => {
    if (fieldName === "writOrderDate") {
       if (isSelectedFirstReason(fieldName, rowIndex)) {
          return (
             renderNotApplicableField(true)
          )
       };
       return (
          <div className="fields_validation">
             <DatePicker
                selected={value ? new Date(value) : null}
                onChange={(date) => {
                   // Convert date to a formatted string or use an empty string if date is null
                   const dateString = date ? date.toLocaleDateString() : '';

                   handleInputChange?.(fieldName, dateString, rowIndex);
                }}
                //minDate={new Date()}
                dateFormat="MM/dd/yyyy"
                placeholderText="mm/dd/yyyy"
                disabled={data.county.trim().toLowerCase() === "gwinnett" ? true : false}
                className={'bg-calendar peer placeholder-gray-500 outline-none p-2.5 py-1.5 block border w-full border-gray-200 rounded-md text-xs placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none bg-no-repeat bg-[center_right_8px] min-w-28 pr-6'
                }
             />
             {showErrors && columnErrors[rowIndex] && columnErrors[rowIndex][fieldName] && (
                <div className="text-red-500 mb-1">
                   {columnErrors[rowIndex][fieldName].map((error, index) => (
                      <div key={index}>
                         {error.errorMessage}
                      </div>
                   ))}
                </div>
             )}
          </div>
       )
    };
    if (fieldName === "paymentAmountOwned") {
       return (
          <div className="fields_validation">
             <input
                type={isSelectedFirstReason(fieldName, rowIndex) || isSelectedThirdReason(fieldName, rowIndex) ? "text" : "number"}
                value={isSelectedFirstReason(fieldName, rowIndex) || isSelectedThirdReason(fieldName, rowIndex) ? "N/A" : value as string}
                readOnly={isSelectedFirstReason(fieldName, rowIndex) || isSelectedThirdReason(fieldName, rowIndex)}
                disabled={isSelectedFirstReason(fieldName, rowIndex) || isSelectedThirdReason(fieldName, rowIndex) || data.county.trim().toLowerCase() === "gwinnett"}
                style={{
                   backgroundImage: `url(${dollarImage})`,
                }}
                min={0}
                className={`peer outline-none p-2.5 py-1.5 block border w-full border-gray-200 rounded-md text-xs placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none bg-no-repeat bg-[center_left_10px] !pl-7  `}
                onChange={(e) =>
                   handleInputChange?.(fieldName, e.target.value as string, rowIndex)
                }
             />
             {showErrors && columnErrors[rowIndex] && columnErrors[rowIndex][fieldName] && (
                <div className="text-red-500 mb-1">
                   {columnErrors[rowIndex][fieldName].map((error, index) => (
                      <div key={index}>
                         {error.errorMessage}
                      </div>
                   ))}
                </div>
             )}
          </div>
       )
    }
    if (fieldName === "paymentDueOn") {
       if (isSelectedFirstReason(fieldName, rowIndex) || isSelectedThirdReason(fieldName, rowIndex)) {
          return (
             renderNotApplicableField(true)
          )
       }
       return (
          <div className="fields_validation">
             <DatePicker
                selected={value ? new Date(value) : null}
                onChange={(date) => {
                   // Convert date to a formatted string or use an empty string if date is null
                   const dateString = date ? date.toLocaleDateString() : '';
                   handleInputChange?.(fieldName, dateString, rowIndex);
                }}
                disabled={data.county.trim().toLowerCase() === "gwinnett" ? true : false}
                // minDate={new Date()}
                dateFormat="MM/dd/yyyy"
                placeholderText="mm/dd/yyyy"
                className={'bg-calendar peer placeholder-gray-500 outline-none p-2.5 py-1.5 block border w-full border-gray-200 rounded-md text-xs placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none bg-no-repeat bg-[center_right_8px] min-w-28 pr-6'}
             />
             {showErrors && columnErrors[rowIndex] && columnErrors[rowIndex][fieldName] && (
                <div className="text-red-500 mb-1">
                   {columnErrors[rowIndex][fieldName].map((error, index) => (
                      <div key={index}>
                         {error.errorMessage}
                      </div>
                   ))}
                </div>
             )}
          </div>
       )
    };
    if (fieldName === "writApplicantPhone") {
       return (
          <div className="fields_validation">
             <InputMask
                mask="(999) 999-9999"
                maskChar=" "
                value={(value as any)
                }
                onChange={(e: any) =>
                   handleInputChange?.(fieldName, e.target.value, rowIndex)
                }
                onBlur={(e: any) =>
                   handleInputChange?.(fieldName, e.target.value, rowIndex)
                }
                name={fieldName}
                id={fieldName + rowIndex}
                disabled={data.county.trim().toLowerCase() === "gwinnett" ? true : false}
                className="peer outline-none p-2.5 block border w-full border-gray-200 rounded-lg text-xs focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none h-[31px]" // Custom class for styling
             />
             {showErrors && columnErrors[rowIndex] && columnErrors[rowIndex][fieldName] && (
                <div className="text-red-500 mb-1">
                   {columnErrors[rowIndex][fieldName].map((error, index) => (
                      <div key={index}>
                         {error.errorMessage}
                      </div>
                   ))}
                </div>
             )}
          </div>
       )
    };
    if (fieldName === "hasSSN") {
       if (!isScraRequiredForIndex(rowIndex)) {
          return (
             renderNotApplicableField(true)
          )
       }
       return (
          <div className="fields_validation">
             <div className="flex gap-3">
                <div className="flex items-center">
                   <input
                      id={`${fieldName}1`}
                      type="radio"
                      value={"Yes"}  // Set appropriate values for 'Yes' option
                      name={`${fieldName}-${rowIndex}`}
                      disabled={data.county.trim().toLowerCase() === "gwinnett" ? true : false}
                      className="w-3.5 h-3.5 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2 outline-0"
                      onChange={(e) => handleInputChange?.(fieldName, true, rowIndex)}
                     //  checked={value === "Yes"}
                     checked={value}
                   />
                   <label htmlFor={`${fieldName}${rowIndex}`} className="ms-1 text-xs font-medium text-gray-900 ">Yes</label>
                </div>
                <div className="flex items-center">
                   <input
                      id={`${fieldName}2`}
                      type="radio"
                      value="No"  // Set appropriate values for 'No' option
                      name={`${fieldName}-${rowIndex}`}
                      disabled={data.county.trim().toLowerCase() === "gwinnett" ? true : false}
                      className="w-3.5 h-3.5 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2 outline-0"
                      onChange={(e) => handleInputChange?.(fieldName, false, rowIndex)}
                     //  checked={value === "No"}
                     checked={!value}
                   />
                   <label htmlFor={`${fieldName}2`} className="ms-1 text-xs font-medium text-gray-900 ">No</label>
                </div>
             </div>
             {showErrors && columnErrors[rowIndex] && columnErrors[rowIndex][fieldName] && (
                <div className="text-red-500 mb-1">
                   {columnErrors[rowIndex][fieldName].map((error, index) => (
                      <div key={index}>
                         {error.errorMessage}
                      </div>
                   ))}
                </div>
             )}
          </div>
       )
    };
    if (fieldName === "isCorporation") {
       if (!isScraRequiredForIndex(rowIndex)) {
          return (
             renderNotApplicableField(true)
          )
       }
       const selectedReason = filteredRecords[rowIndex]?.selectedReason ?? "";
       // Check if the field should be visible based on the selected reason
       if (!(selectedReason === "0" || selectedReason === "" || selectedReason === undefined)) {
          return <div className="fields_validation">N/A</div>;
       };

       return (
          <div className="fields_validation">
             <div className="radio_btns">
                <div className="flex gap-3 items-center">
                   <div className="flex items-center">
                      <input
                         id="radio1"
                         type="radio"
                         value={"Yes"}  // Set appropriate values for 'Yes' option
                         name={`radio-${rowIndex}`}
                         className="w-3.5 h-3.5 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2 outline-0"
                         onChange={(e) => handleInputChange?.(fieldName, true, rowIndex)}
                        //  checked={value === "Yes"}
                        checked={value}
                         disabled={selectedReason !== "0" && selectedReason !== ""}
                      />
                      <label htmlFor="radio1" className="ms-1 text-xs font-medium text-gray-900 ">Yes</label>
                   </div>
                   <div className="flex items-center">
                      <input
                         id="radio2"
                         type="radio"
                         value="No"  // Set appropriate values for 'No' option
                         name={`radio-${rowIndex}`}
                         className="w-3.5 h-3.5 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2 outline-0"
                         onChange={(e) => handleInputChange?.(fieldName, false, rowIndex)}
                         checked={!value}
                         disabled={selectedReason !== "0" && selectedReason !== ""}
                      />
                      <label htmlFor="radio2" className="ms-1 text-xs font-medium text-gray-900 ">No</label>
                   </div>
                </div>
                {showErrors && columnErrors[rowIndex] && columnErrors[rowIndex][fieldName] && (
                   <div className="text-red-500 mb-1">
                      {columnErrors[rowIndex][fieldName].map((error, index) => (
                         <div key={index}>
                            {error.errorMessage}
                         </div>
                      ))}
                   </div>
                )}
             </div>
          </div>
       )
    };
    if (fieldName === "militaryStatusReport") {
       if (!isScraRequiredForIndex(rowIndex)) {
          return (
             renderNotApplicableField(true)
          )
       }
       const isCorporationYes = String(filteredRecords[rowIndex]?.isCorporation) === "Yes";
       const plaintiffNotAcceptedMoney = filteredRecords[rowIndex]?.selectedReason === "0";
       const tenantNames: ITenant[] = filteredRecords[rowIndex]?.tenantNames;
       const uploadedReports = tenantNames.filter((tenant) => tenant.isMilitaryStatusUploaded);
       filteredRecords[rowIndex].militaryStatusReport = uploadedReports.length === tenantNames.length ? "UPLOADED" : "";

       if (isCorporationYes && plaintiffNotAcceptedMoney) {
          return <span className="flex items-center text-xs font-medium text-gray-600" >Upload Report(s)</span>;
       };

       return (
          <div className="fields_validation">
             <div className="flex gap-1">
                {uploadedReports.map((tenant: ITenant) => (
                   <div
                      key={tenant.id}
                      className="relative flex select-none items-center whitespace-nowrap rounded-md bg-[#3b82f633] px-2 py-1 font-sans text-[10px] font-bold uppercase text-[#3b82f6]"
                   >
                      <a
                         target="_blank"
                         onClick={() => openPdf(tenant.militaryStatusDocURL)}
                         className="cursor-pointer"
                      >
                         {tenant.firstName}
                      </a>
                      <a
                         onClick={() => handleRemoveMSReport(tenant)}
                         className="cursor-pointer"
                      >
                         <FaTimes />
                      </a>
                   </div>
                ))}
             </div>
             {uploadedReports.length !== tenantNames.length && (
                <a
                   className="text-xs font-medium text-blue-600 cursor-pointer"
                   onClick={() => handleMilitaryStatusReport(rowIndex)}
                >
                   Upload Report(s)
                </a>
             )}
             {showErrors && columnErrors[rowIndex]?.militaryStatusReport?.map((error, index) => (
                <div key={index} className="text-red-500 ml-2 mb-1">
                   {error.errorMessage}
                </div>
             ))}
          </div>
       );
    };
    return (
       <div className="fields_validation">
          <input
             type={"text"}
             value={isSelectedFirstReason(fieldName, rowIndex) ? "N/A" : value as string}
             readOnly={isSelectedFirstReason(fieldName, rowIndex)}
             disabled={isSelectedFirstReason(fieldName, rowIndex) || data.county.trim().toLowerCase() === "gwinnett"}
             className={
                "peer outline-none p-2.5 py-1.5 block border w-full rounded-md text-xs placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none    "
             }
             onChange={(e) =>
                handleInputChange?.(fieldName, e.target.value, rowIndex)
             }
          />
          {showErrors && columnErrors[rowIndex] && columnErrors[rowIndex][fieldName] && (
             <div className="text-red-500 mb-1">
                {columnErrors[rowIndex][fieldName].map((error, index) => (
                   <div key={index}>
                      {error.errorMessage}
                   </div>
                ))}
             </div>
          )}
       </div>
    );
 };
  const formattedDataCell = (
    value: any,
    columnName: any,
    fieldName: any,
    rowIndex: number,
    data: any,
  ) => (
    <>
      <input
        type={"text"}
        value={value as string}
        className={
          "peer outline-none p-2.5 py-1.5 block border w-full rounded-md text-xs placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none    "
        }
        onChange={(e) =>
          handleInputChange?.(fieldName, e.target.value, rowIndex)
        }
      />
      {columnErrors[rowIndex]?.[columnName]?.map(
        (error, index) => (
          <div key={index} className="text-red-500">
            {error.errorMessage}
          </div>
        )
      )}
    </>
  );
  return (
    <>
      <Modal
        showModal={props.showAddtoWritLaborModalPopup}
        onClose={handleModalClose}
        width="max-w-4xl"
      >
        {showSpinner && <Spinner />}
        {/* Container for the content */}
        <div id="fullPageContent">
          <div className="bg-white px-3.5 pb-3.5 pt-4 sm:p-5 sm:pb-3.5">
            <div className="sm:flex sm:items-start">
              <div className="text-center sm:text-left">
                <h3
                  className="leading-5 text-gray-900 text-[16px] md:text-xl mb-1.5"
                  id="modal-title"
                >
                  Writs Of Possession
                </h3>
              </div>
            </div>

            {/* Display the grid*/}
            <div className="relative pt-2 writlabor-writofpossession-grid">
              <Grid
                columnHeading={visibleColumns}
                rows={filteredRecords}
                handleSelectAllChange={handleSelectAllChange}
                selectAll={selectAll}
                showInPopUp={true}
                cellRenderer={(
                  data: IWritsOfPossessionItems,
                  rowIndex: number,
                  cellIndex: number
                ) => {
                  return handleCellRendered(cellIndex, data, rowIndex);

                }}
              ></Grid>
              <div className="py-2.5 flex justify-between mt-1.5 items-center">
                <p className="font-semibold text-[10px] md:text-xs">Total records: {selectedWritsOfPossessionId.length}</p>

                <Button
                    handleClick={()=>setShowConfirm(true)}
                    title="Confirm"
                    isRounded={false}
                    type={"button"} 
                  ></Button>
              </div>
            </div>
          </div>
        </div>

      </Modal>
      <div>
        <Modal
          showModal={showConfirm}
          onClose={handleModalClose}
          width="max-w-md"
        >
          {showSpinner && <Spinner />}
          {/* Container for the content */}
          <div id="fullPageContent">
            <div className="bg-white px-3.5 pb-3.5 pt-4 sm:p-5 sm:pb-3.5">
              <div className="text-center pr-4 sm:text-left">
                <h3
                  className="text-sm font-semibold leading-5 text-gray-900"
                  id="modal-title"
                >
                 Are you sure you want to save the Information for Writs of Possession?
                </h3>
              </div>
            </div>
            <div className="flex justify-end m-2">
              <Button
                type="button"
                isRounded={false}
                title="No"
                handleClick={handleModalClose}
                classes="text-[11px] md:text-xs bg-white	inline-flex justify-center items-center rounded-md text-md font-semibold py-2 md:py-2.5 px-4 md:px-5 mr-1.5 ring-1 ring-slate-900/10 hover:bg-white/25 hover:ring-slate-900/15 shadow-lg "
              ></Button>
              <Button
                handleClick={()=>handleSubmit()}
                title="Yes"
                isRounded={false}
                type={"button"}
                classes="text-[11px] md:text-xs bg-[#2472db] inline-flex justify-center items-center rounded-md font-semibold py-2 md:py-2.5 px-4 md:px-5 text-white"
              ></Button>
            </div>
          </div>
        </Modal>
      </div>
      
        <WritsOfPossessionDocumentUploader
               open={showUploadReports}
               setOpen={() => setShowUploadReports(false)}
               selectedCaseForWrits={uploadReportsForCase}
               setSelectedCaseForWrits={(data: ISelectedWrit) => setUploadReportsForCase(data)}
            />
    </>
  );

};

export default WritsOfPossession_AddtoWritLaborModal;
